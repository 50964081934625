

























































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { AccountType } from "@/model/Enums";
import { IAccount, Account } from "@/model/Account";
import { INotice, Notice } from "@/model/Notice";
import { IProperty, Property } from "@/model/Property";
import apiClient from  "@/utilities/ApiClient";
import store from "@/store/store";

@Component({})
export default class Home extends Vue {

    async mounted() {
        const getShowWarning = async () => { await this.getShouldShowMessage(); };
        const getProperty = async () => { await this.getProperty(); };
        const getAccounts = async () => { await this.getAccounts(); };
        const getNotices = async () => { await this.getNotices(); };
        await Promise.all([
            getShowWarning(),
            getProperty(),
            getAccounts(),
            getNotices()
        ]);
    }

    //
    // -- properties
    //

    showWelcomeMessage: boolean = false;
    property = new Property();
    accounts: Array<Account> = [];
    notices: Array<Notice> = [];

    //
    // -- computed properties
    //       

    private get signedInUserName(): string {
        return store.getters.signedInUserName;
    }

    //
    // -- watchers
    //

    @Watch("showWelcomeMessage")
    private onShowMessageChanged(value: boolean) {
        if (!value) {
            this.welcomeMessageDismissed();
        }
    }

    // 
    // -- methods
    //

    private async getShouldShowMessage() {
        var shouldShow: boolean = await apiClient.get("/api/user/showWelcomeMessage");
        this.showWelcomeMessage = shouldShow;
    }

    private async getProperty() {
        var serverData: IProperty = await apiClient.get("/api/property/load");
        this.property.update(serverData);
    }

    private async getAccounts() {
        this.accounts = [];
        var serverData: Array<IAccount> = await apiClient.get("/api/occupancy/accounts");
        this.accounts.push(...serverData.map(a => new Account(a)));
    }

    private async getNotices() {
        this.notices = [];
        var serverData: Array<INotice> = await apiClient.get("/api/occupancy/notices")
        this.notices.push(...serverData.map(n => new Notice(n)));
    }

    private welcomeMessageDismissed() {
        apiClient.get("/api/user/dismissWelcomeMessage");
    }

    accountClass(account: Account): string {
        return account.balance >= 0 ? "account-credit" : "account-arrears"
    }

    private accountIcon(account: Account): string {
        if (account.id == AccountType.Rent) return "mdi-caravan";
        else if (account.id == AccountType.PitchFee) return "mdi-map-marker";
        else if (account.id == AccountType.Water) return "mdi-water";
        else if (account.id == AccountType.Electric) return "mdi-flash";
        return "mdi-file-document";
    }

    private accountBlanceText(account: Account): string {
        if (account.balance > 0) return "in credit";
        if (account.balance < 0) return "in arrears";
        return "";
    }

}

import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface INotice {
    date: Date;
    site: string;
    title: string;
    notice: string;
    important: boolean;
}

export class Notice implements INotice {

    constructor(data?: INotice) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: INotice) {
        mapData(data, { root: () => this });
    }

    date: Date = new Date(utils.emptyDateValue);
    site: string = "";
    title: string = "";
    notice: string = "";
    important: boolean = false;

    get colour(): string {
        return this.important ? "#B0615D" : "#679e6a";
    }

    get icon(): string {
        return this.important ? "mdi-exclamation-thick" : "";
    }

}
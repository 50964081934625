import { mapData } from "@/utilities/DataMapping";

export interface IAccount {
    id: number;
    description: string;
    balance: number;
}

export class Account implements IAccount {    

    constructor(data?: IAccount) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IAccount) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    description: string = "";
    balance: number = 0;

    /* billing info */
    amountToPay: number|null = null;

}